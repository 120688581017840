import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import branding from '../../../assets/img/branding.png';
import branding1 from '../../../assets/img/branding1.PNG';
import branding2 from '../../../assets/img/branding2.PNG';
import branding3 from '../../../assets/img/branding3.PNG';

const images = [branding, branding1, branding2, branding3];

const BrandingPage = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const intervalRef = useRef(null);

  const startRolling = () => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        setCurrentImage((prev) => (prev + 1) % images.length);
      }, 1000);
    }
  };

  const stopRolling = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  };

  useEffect(() => {
    return () => clearInterval(intervalRef.current); // Cleanup on unmount
  }, []);

  return (
    <div className='p-8'>
      <div className='flex items-center justify-between p-2 pl-8 pr-36'>
        <h2 className='text-3xl font-bold'>Branding</h2>
        <button className='border border-2 border-[#FD5F20] rounded-lg'>
          <span className='text-2xl text-[#FD5F20] cursor-pointer hover:underline m-2'>
            Try Now 
            <FontAwesomeIcon icon={faArrowUp} className='transform rotate-45 ml-2' />
          </span>
        </button>
      </div>
      <div 
        className='mt-4 w-full max-w-full mx-auto overflow-hidden rounded-lg' 
        onMouseEnter={startRolling} 
        onMouseLeave={stopRolling}
      >
        <img 
          src={images[currentImage]} 
          alt='Branding' 
          className='w-full h-auto transition-transform duration-500 ease-in-out transform hover:scale-105' 
        />
      </div>
    </div>
  );
};

export default BrandingPage;
