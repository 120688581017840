import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { api } from '../../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ConsultationForm = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Select Your Industry');
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        industry: ''
    });
    const [errors, setErrors] = useState({});

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setFormData({ ...formData, industry: option });
        setIsOpen(false);
    };

    const options = [
        'Solopreneurs', 'Personal Training', 'Beauty Spa', 'Bakery & Spa',
        'Bakery & Cafe', 'Consultancy', 'Coaching Center', 'Boutique Services',
        'Clinics & Medicals', 'Other Services',
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.phone) newErrors.phone = 'Phone number is required';
        if (!formData.email) newErrors.email = 'Email is required';
        if (!formData.industry || formData.industry === 'Select Your Industry') 
            newErrors.industry = 'Industry selection is required';
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            const response = await api.post('/consultations', formData);
            toast.success('Consultation request submitted successfully!');
            setFormData({ name: '', phone: '', email: '', industry: '' });
            setSelectedOption('Select Your Industry');
        } catch (error) {
            toast.error('Error submitting form. Please try again.');
        }
    };

    return (
        <div className="max-w-lg mx-auto bg-white p-6 rounded-lg shadow-lg">
            <ToastContainer position="top-right" autoClose={5000} />
            <form onSubmit={handleSubmit} className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Name"
                            className={`w-full border-2 p-2 rounded-md focus:ring-2 ${
                                errors.name ? 'border-red-500' : 'border-[#FD5F20]'
                            }`}
                        />
                        {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                    </div>
                    <div>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="Phone"
                            className={`w-full border-2 p-2 rounded-md focus:ring-2 ${
                                errors.phone ? 'border-red-500' : 'border-[#FD5F20]'
                            }`}
                        />
                        {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Email"
                            className={`w-full border-2 p-2 rounded-md focus:ring-2 ${
                                errors.email ? 'border-red-500' : 'border-[#FD5F20]'
                            }`}
                        />
                        {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                    </div>
                    <div className="relative">
                        <div
                            onClick={toggleDropdown}
                            className={`w-full border-2 p-2 rounded-md cursor-pointer flex justify-between items-center ${
                                errors.industry ? 'border-red-500' : 'border-[#FD5F20]'
                            }`}
                        >
                            {selectedOption}
                            <FontAwesomeIcon icon={faChevronDown} className='text-[#FD5F20]' />
                        </div>
                        {isOpen && (
                            <ul className="absolute left-0 w-full mt-1 bg-white border border-[#FD5F20] rounded-md shadow-lg z-50">
                                {options.map((option) => (
                                    <li
                                        key={option}
                                        className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                                        onClick={() => handleOptionClick(option)}
                                    >
                                        {option}
                                    </li>
                                ))}
                            </ul>
                        )}
                        {errors.industry && <p className="text-red-500 text-sm">{errors.industry}</p>}
                    </div>
                </div>

                <button
                    type="submit"
                    className="w-full bg-[#FD5F20] text-white p-2 rounded-md font-bold hover:bg-opacity-90 transition"
                >
                    Get Free Consultation
                </button>
            </form>
        </div>
    );
};

export default ConsultationForm;
