import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import thumbnail from '../../../assets/img/Thumbnail.PNG'; // Replace with actual thumbnail image
import vid from '../../../assets/Video/marketing.mp4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

const videos = [
  { title: 'Video 1', url: vid },
  { title: 'Video 2', url: vid },
  { title: 'Video 3', url: vid },
  { title: 'Video 4', url: vid },
  { title: 'Video 5', url: vid },
  { title: 'Video 6', url: vid },
  { title: 'Video 7', url: vid },
  { title: 'Video 8', url: vid },
  { title: 'Video 9', url: vid },
];

const VideoProduction = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const openModal = (video) => {
    setCurrentVideo(video);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentVideo(null);
  };

  return (
    <div className="container mx-auto px-4 max-w-screen-xl py-12">
      <h2 className="text-5xl font-extrabold text-center mb-10 text-[#FD5F20]">
        Our Video Productions
      </h2>

      {/* Video Grid with Masonry Layout */}
      {/* Video Grid with Masonry Layout */}
<div className="w-full max-w-full px-2 sm:px-4 lg:px-0 overflow-hidden">
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
    {videos.map((video, index) => (
      <div
        key={index}
        className="relative group rounded-lg overflow-hidden shadow-lg cursor-pointer hover:scale-105 transition-transform"
        onClick={() => openModal(video)}
      >
        <img
          src={thumbnail}
          alt={`Thumbnail for ${video.title}`}
          className="w-full h-60 object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
          <div className="w-16 h-16 bg-white rounded-full flex items-center justify-center shadow-lg">
            <FontAwesomeIcon icon={faPlay} className="text-[#FD5F20] text-3xl" />
          </div>
        </div>
        <div className="absolute bottom-0 left-0 w-full p-4 bg-gradient-to-t from-black to-transparent">
          <h3 className="text-lg font-semibold text-white">{video.title}</h3>
        </div>
      </div>
    ))}
  </div>
</div>


      {/* Video Carousel */}
      <h3 className="text-3xl font-semibold text-center mb-6 text-[#FD5F20]">
        More Featured Videos
      </h3>
      <Swiper
  modules={[Pagination, Navigation]}
  spaceBetween={16}
  slidesPerView={1} // Default for very small screens
  pagination={{ clickable: true }}
  navigation={true}
  breakpoints={{
   // Phones (landscape & small tablets)
    768: { slidesPerView: 2 },  // Tablets (portrait)
    1024: { slidesPerView: 2 }, // Tablets (landscape) / Small desktops
    1280: { slidesPerView: 3 }, // Large desktops
    1536: { slidesPerView: 3 }, // Extra large screens
  }}
  className="mb-12 w-full overflow-hidden"  // Added overflow-hidden here
>
  {videos.map((video, index) => (
    <SwiperSlide key={index} className="w-full">
      <div
        className="relative group rounded-lg overflow-hidden shadow-lg cursor-pointer hover:scale-105 transition-transform"
        onClick={() => openModal(video)}
      >
        <img
          src={thumbnail}
          alt={`Thumbnail for ${video.title}`}
          className="w-full h-60 object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
          <FontAwesomeIcon icon={faPlay} className="text-[#FD5F20] text-3xl" />
        </div>
      </div>
    </SwiperSlide>
  ))}
</Swiper>


      {/* Video Modal */}
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Video Modal"
        className="modal-content bg-gray-900 rounded-lg p-0 w-full max-w-4xl mt-8 mx-auto relative z-50 max-h-[80vh] overflow-hidden"
        overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-40"
        ariaHideApp={false}
      >
        {currentVideo && (
          <div className="relative w-full h-full flex flex-col">
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-white text-3xl font-bold"
            >
              &times;
            </button>
            <div className="relative w-full h-[60vh] rounded-lg overflow-hidden">
              <video
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={currentVideo.url}
                title={currentVideo.title}
                controls
                autoPlay
              />
            </div>
            <h3 className="mt-4 text-xl font-semibold text-white text-center">
              {currentVideo.title}
            </h3>
          </div>
        )}
      </ReactModal>
    </div>
  );
};

export default VideoProduction;