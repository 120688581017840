import { useState, useEffect } from "react";
import ReactCountryFlag from "react-country-flag";

const LanguageSwitcher = () => {
  const [selectedCountry, setSelectedCountry] = useState({ code: "US", label: "EN" });
  const [isOpen, setIsOpen] = useState(false);

  const languages = [
    { code: "US", label: "EN" },
    { code: "ET", label: "AM" },
  ];

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".language-switcher")) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    <div className="relative inline-block text-left language-switcher">
      {/* Selected Language Button */}
      <p
        className="flex items-center  text-[#FD5F20] rounded-md hover:text-[#FD5F20]/80 cursor-pointer transition"
        onClick={() => setIsOpen(!isOpen)}
      >
        <ReactCountryFlag countryCode={selectedCountry.code} svg className="w-6 h-6" />
        <span className="ml-2">{selectedCountry.label}</span>
      </p>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute left-0 mt-2 w-28 bg-white shadow-lg rounded-md overflow-hidden">
          {languages.map((lang) => (
            <button
              key={lang.code}
              onClick={() => {
                setSelectedCountry(lang);
                setIsOpen(false);
              }}
              className="flex items-center w-full px-4 py-2 text-gray-800 hover:bg-gray-200 transition"
            >
              <ReactCountryFlag countryCode={lang.code} svg className="w-6 h-6" />
              <span className="ml-2">{lang.label}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
