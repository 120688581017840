import React from "react";
import { motion } from "framer-motion";
import { Card, CardContent } from "../ui/card";  // Adjust the relative path
import { Button } from "../ui/button";  // Adjust the relative path
import { CalendarDays, MapPin } from "lucide-react";
import fas1 from '../../assets/gena/fas1.PNG';
import fas2 from '../../assets/gena/fas2.PNG';
import fas3 from '../../assets/gena/fas3.PNG';
import fas4 from '../../assets/gena/fas4.PNG';
import tas2 from '../../assets/gena/tas2.PNG';

const events = [
  {
    id: 1,
    title: "Easter Carnival",
    date: "March 30, 2024",
    location: "Downtown Park",
    image: fas1,
    description: "Join us for a day of fun, food, and festivities! Enjoy live music, games, and great food.",
  },
  {
    id: 2,
    title: "Spring Gala Night",
    date: "April 2, 2024",
    location: "Grand Ballroom",
    image: fas2,
    description: "An elegant evening of music, dance, and entertainment with a special guest performance.",
  },
  {
    id: 3,
    title: "Easter Egg Hunt",
    date: "April 5, 2024",
    location: "Sunrise Park",
    image: fas3,
    description: "A fun-filled event for kids with games, surprises, and lots of chocolate eggs!",
  },
  {
    id: 4,
    title: "Spring Food Festival",
    date: "April 8, 2024",
    location: "City Center Plaza",
    image: fas4,
    description: "Indulge in delicious seasonal treats, street food, and artisan delicacies.",
  },
  {
    id: 5,
    title: "Family Picnic & Movie Night",
    date: "April 12, 2024",
    location: "Greenwood Park",
    image: tas2,
    description: "Bring your family and friends for an outdoor picnic followed by a classic Easter movie screening.",
  },
];

const EventShowcase = () => {
  return (
    <div className="min-h-screen mt-28 mb-4 bg-gradient-to-b from-orange-400 to-pink-600 p-10">
      <h1 className="text-5xl font-bold text-center text-white mb-12">
        ✨ Upcoming Easter Events 🎉
      </h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">
        {events.map((event) => (
          <motion.div
            key={event.id}
            whileHover={{ scale: 1.05 }}
            className="bg-white rounded-2xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-2xl"
          >
            <div className="relative">
              <img src={event.image} alt={event.title} className="w-full h-56 object-cover" />
              <div className="absolute top-2 right-2 bg-white text-gray-800 px-3 py-1 rounded-full shadow">
                {event.date}
              </div>
            </div>
            <Card className="p-6">
              <CardContent>
                <h2 className="text-2xl font-bold text-gray-900">{event.title}</h2>
                <div className="flex items-center text-gray-600 mt-2">
                  <CalendarDays className="w-5 h-5 mr-2 text-orange-500" />
                  <span>{event.date}</span>
                </div>
                <div className="flex items-center text-gray-600 mt-1">
                  <MapPin className="w-5 h-5 mr-2 text-blue-500" />
                  <span>{event.location}</span>
                </div>
                <p className="mt-3 text-gray-700 leading-relaxed">{event.description}</p>
                <Button className="mt-4 bg-[#FD5F20] text-white w-full hover:bg-orange-700 shadow-md transition-all duration-300">
                  Get Tickets
                </Button>
              </CardContent>
            </Card>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default EventShowcase;
