import MarqueeSlider from "react-marquee-slider";
import times from "lodash/times";
import icon1 from "../assets/Icons/pngegg (17) 1.png";
import icon2 from "../assets/Icons/pngegg (19) 1.png";
import icon3 from "../assets/Icons/pngegg (20) 1.png";
import icon4 from "../assets/Icons/pngegg (21) 1.png";
import icon5 from "../assets/Icons/pngegg (22) 1.png";

const images = [icon1, icon2, icon3, icon4, icon5];

const ClientFeedback = () => {
  return (
    <div className="h-auto flex w-full flex-col relative overflow-hidden">
      {/* Title Section */}
      <div className="text-center mb-6">
  <h1 className="text-4xl font-bold text-gray-900 inline-block border-b-4 border-[#FD5F20] pb-2 cursor-pointer">
    Our Satisfied Clients
  </h1>
</div>




      {/* Scrolling Marquee */}
      <div className="w-full overflow-hidden relative">
        {/* Gradient fade only near the edges */}
        <div className="absolute inset-0 z-10 pointer-events-none">
          <div className="absolute left-0 top-0 bottom-0 w-20 bg-gradient-to-r from-white via-transparent"></div>
          <div className="absolute right-0 top-0 bottom-0 w-20 bg-gradient-to-l from-white via-transparent"></div>
        </div>

        <MarqueeSlider velocity={12} minScale={0.8} resetAfterTries={200} scatterRandomly={false}>
          {times(3, () =>
            images.map((img, index) => (
              <div key={index} className="px-2 inline-block">
                <img
                  src={img}
                  className="w-20 h-auto transition-transform duration-300 transform hover:scale-110"
                  alt={`Client ${index + 1}`}
                />
              </div>
            ))
          )}
        </MarqueeSlider>
      </div>
    </div>
  );
};

export default ClientFeedback;
