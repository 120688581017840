import React from 'react';
import customer from '../assets/img/customer.png';
import tes from '../assets/img/test1.png';
import tes2 from '../assets/img/Test6.png';
import tes3 from '../assets/img/Test3.png';
import tes4 from '../assets/img/Test4.png'; // Assuming you have a new image for the fourth testimonial
import tes5 from '../assets/img/Test7.png'; // Assuming you have a new image for the fifth testimonial

const TestimonialSection = () => {
  return (
    <div className="min-h-auto overflow-hidden relative">
      <div className="">
        <h2 className="text-3xl font-semibold text-center mb-12">Testimonials</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2">
          <TestimonialCard
            text="As a new business, we required to build our online presence. Ato Creative assisted us with the website, brand design, and social media launch. Due to their help, we are now experiencing growth."
            author="Solomon Gebremariam"
            position="Chief Technology Officer"
            photoUrl={tes}
          />
          <TestimonialCard
            text="Ato Creative created our new brand look. This changed our business. We now have 30% more leads. They understood our vision and made a design that people connect with."
            author="Tewodros Kebede"
            position="General Manager"
            photoUrl={tes2}
          />
          <TestimonialCard
            text="The team at Ato Creative responds quickly and is flexible. They were able to adjust to our changes and provided good work within short timeframes."
            author="Selamawit Mekonnen"
            position="Senior Consultant & CEO"
            photoUrl={tes3}
          />
          <TestimonialCard
            text="Ato Creative's SEO and social media increased our online store visits by 70% in three months, resulting in more sales. We're very satisfied."
            author="Dr. Dawit Solomon"
            position="Operations Director"
            photoUrl={tes4}
          />
          <TestimonialCard
            text="We knew we needed better content, but lacked the knowledge. Ato Creative provided a strategy, resulting in more engagement and greater brand awareness through their blog posts and emails."
            author="Meron Hailu"
            position="Creative Director"
            photoUrl={tes5}
          />
        </div>
      </div>
    </div>
  );
};

const TestimonialCard = ({ text, author, position, photoUrl }) => {
  return (
    <div className="bg-white border border-gray-300 rounded-lg shadow-[0px_4px_15px_rgba(0,0,0,0.6)] transition-transform transform scale-100 hover:scale-110 hover:border-gray-400 overflow-hidden flex flex-col">
      {/* Testimonial Text */}
      <div className="p-6 flex-1">
        <div className="testimonial-text" dangerouslySetInnerHTML={{ __html: text }} />
      </div>

      {/* Footer with image, name, and position */}
      <div className="p-6 flex items-center justify-start ">
        {/* Profile Image */}
        <img
          className="w-8 h-8 object-cover rounded-full border-2 mr-2 border-[#FD5F20] shadow-lg"
          src={photoUrl}
          alt={`Testimonial from ${author}`}
        />
        <div className="text-lg font-semibold">{author}</div>
        </div>
        <div className="ml-4">
          {/* Author Name */}
          
          {/* Position */}
          <div className="mb-4 text-gray-500">{position}</div>
        </div>
    
    </div>
  );
};

export default TestimonialSection;
