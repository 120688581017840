import { useState, useEffect } from "react";
import { X } from "lucide-react";
import messageIcon from "../../../assets/Icons/Asset 4.svg";
import FAQPage from "./faq";// Assuming FAQPage is in the same directory

const FAQPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [bounceCount, setBounceCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state

  useEffect(() => {
    // Show after 10 seconds
    const timer = setTimeout(() => setIsOpen(true), 10000);

    // Detect exit intent
    const handleMouseLeave = (event) => {
      if (event.clientY < 10) {
        setIsOpen(true);
      }
    };
    document.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      clearTimeout(timer);
      document.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  useEffect(() => {
    if (isOpen && bounceCount < 5) {
      const bounceTimer = setInterval(() => {
        setBounceCount((prev) => prev + 1);
      }, 500);

      return () => clearInterval(bounceTimer);
    }
  }, [isOpen, bounceCount]);

  return (
    <div>
      {isOpen && (
        <div
          className={`fixed bottom-12 right-5 bg-gradient-to-r from-[#FD5F20] to-[#FF7F3D] shadow-xl rounded-full w-20 h-20 flex justify-center items-center transform transition-all duration-300 ${
            bounceCount < 5 ? "animate-bounce" : ""
          }`}
        >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setIsModalOpen(true); // Open the modal on click
            }}
            className="flex justify-center items-center w-full h-full"
          >
            <img
              src={messageIcon}
              alt="Message Icon"
              className="w-full h-full object-cover transform hover:scale-110 transition-all duration-200"
            />
          </a>
        </div>
      )}

      {/* FAQ Modal */}
      {isModalOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white  rounded-lg w-[70vw] h-[80vh] overflow-y-auto">

        <h2 className="text-2xl font-bold bg-[#FD5F20] flex justify-between text-white items-center p-4">Frequently Asked Questions


        <button
          onClick={() => setIsModalOpen(false)}
          className="text-white hover:text-gray-700"
        >
          <X size={24} />
        </button>
        </h2>
      <div className="p-4 m-4">
 
      <FAQPage />
      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default FAQPopup;
