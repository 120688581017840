import React from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareArrowUpRight } from "@fortawesome/free-solid-svg-icons";
import dev from "../../../assets/img/dev.png";
import dev1 from "../../../assets/img/dev1.PNG";
import dev2 from "../../../assets/img/dev2.PNG";
import dev3 from "../../../assets/img/dev3.PNG";

const images = [dev, dev1, dev2, dev3];

export default function SimpleSlider() {
  return (
    <div className="relative w-full h-[600px] flex flex-col items-center bg-[#FCD5C5] bg-opacity-25 p-4 overflow-hidden">
      {/* Header Section */}
      <div className="flex flex-row justify-between w-full px-4">
        <h1 className="text-xl font-extrabold z-50 bg-[#FCD5C5] bg-opacity-25">Web Development</h1>
        <button className="border border-[#FD5F20] p-1 rounded-lg hover:bg-[#FD5F20] hover:text-white transition">
          Try Now
          <FontAwesomeIcon className="pl-2" icon={faSquareArrowUpRight} />
        </button>
      </div>

      {/* Circular Orbit View */}
      <div className="relative w-full h-[400px] flex items-start justify-start">
        {images.map((image, index) => {
          // Calculate the angle for each image
          const angle = (index / images.length) * 3 * Math.PI; // Divide 360° for each image
          const x = Math.cos(angle) * 150; // X-position based on angle (orbit)
          const y = Math.sin(angle) * 100; // Y-position (make it slightly vertical for perspective)

          return (
            <motion.div
              key={index}
              className="absolute"
              style={{
                left: `${x + 50}px`, // Horizontal movement for the orbit, adjusted to move from left top
                top: `${y + 50}px`, // Vertical movement for the orbit, adjusted to move from left top
                zIndex: images.length - index, // Ensure the image in front has higher zIndex
                height: '100%', // Ensure full height
                width: '100%', // Adjust width to maintain aspect ratio
              }}
              animate={{
                rotate: [0, 360], // Animate rotation for each image
              }}
              transition={{
                repeat: Infinity,
                duration: 50, // Slow rotation
                ease: "linear",
                delay: index * 2, // Delay each image's animation to create staggered effect
              }}
            >
              <motion.img
                src={image}
                alt={`Web Development ${index}`}
                className="object-cover rounded-full shadow-lg"
              />
            </motion.div>
          );
        })}
      </div>
    </div>
  );
}
